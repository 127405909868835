:root {
  /* orange */
  --primary: #1746a2;
  /* --primary: #34B7F1; */
  /* light blue */
  --tertiary: #b6cde5;
  /* light gray, almost white */
  --neutral: #fefefe;
  /* dark gray, almost black */
  --dark-neutral: #333333;
  /* 20% opacity */
  --my-shadow: #1b324033;
  /* 45% opacity */
  --my-shadow-hover: #1b324073;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 20px;
  height: min-content; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1E5BD5;
  cursor: pointer; 
}


.ov {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 400px;
}

html {
  scroll-behavior: smooth;
}

* {
  font-family: "Roboto", sans-serif;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  background-color: var(--neutral);
  /* background-color: #EEF1FF; */
  overflow-x: hidden;
}

footer {
  background-color: #dddddd;
  padding: 8px;
}

.navbar-custom {
  background-color: var(--primary);
  color: var(--neutral);
}

.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
  color: var(--neutral);
  font-size: 24px;
  font-weight: 500;
  text-decoration: none;
}

.nav-link {
  color: var(--neutral);
}

.nav-link:hover {
  color: var(--neutral);
  /* transform: translate(4px, 0px); */
  transition: 0.3s all ease;
}

.nav-item {
  padding-right: 12px;
}

nav .bi-list {
  color: var(--neutral);
}

/* #nav-tab .nav-link,
  #myTab .nav-link {
    color: var(--primary);
  } */

.dropdown-item {
  color: var(--dark-neutral);
}
.dropdown-item:active {
  background-color: var(--primary);
}

section {
  padding-top: 92px;
  padding-bottom: 92px;
}

h1 {
  color: var(--primary);
  font-size: 40px;
  font-weight: 500;
  padding-bottom: 20px;
}
#hero {
  text-align: center;
}
#contact h3 {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 20px;
  color: var(--dark-neutral);
}

p {
  color: var(--dark-neutral);
}

.btn {
  box-shadow: 0 4px 12px 0px var(--my-shadow);
  border-radius: 8px;
  transition: 0.3s all ease;
}
.btn:hover {
  box-shadow: 0 4px 16px 0px var(--my-shadow-hover);
  /* transform: translate(4px, 0px); */
}
.btn:active {
  transform: scale(0.98);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.24);
}

.btn-orange {
  color: var(--neutral);
  background-color: var(--primary);
  border-color: var(--primary);
  border-width: medium;
}
.btn-orange:hover {
  color: var(--neutral);
}

.btn-outline-orange {
  color: var(--primary);
  border-color: var(--primary);
  border-width: medium;
  background-color: var(--neutral);
}
.btn-outline-orange:hover {
  color: var(--neutral);
  background-color: var(--primary);
}

.btn-lg {
  width: 100%;
  height: 20vh;
  margin-bottom: 8px;
}

a.btn-lg {
  padding: 8% 5%;
}
a.btn-lg span {
  vertical-align: middle;
}

.canvas-btn {
  border-width: thin;
  box-shadow: none;
  width: 49%;
}
.canvas-btn:hover,
.canvas-btn:active {
  box-shadow: none;
  transform: none;
}

small {
  line-height: 1rem;
}

.user-tag {
  border-left: 1px solid white;
  padding-left: 12px;
}

.modal-footer {
  justify-content: flex-start;
}

#view_nda_modal .modal-body {
  background-color: #f2f0eb;
}

/* #validate-content .heading, */
/* #add-content .heading { */
#dashboard-content .heading {
  font-size: 20px;
  margin-bottom: 4px;
  color: var(--primary);
}

#table-area-pending,
#table-area-signed,
#add-form {
  margin-left: 8px;
  color: var(--dark-neutral);
}

.table-title {
  border-bottom: 2px solid var(--primary);
  font-size: 1.25rem;
}

/* .fa-arrow-right {
    color: var(--primary);
  } */

.form-card {
  background: white;
  padding: 20px 20px;
  border: 1px solid #dddddd;
  border-radius: 0.3rem;
  margin-bottom: 20px;
  box-shadow: 0 4px 12px 0px var(--my-shadow);
}

.form-card legend {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form-card .form-group {
  margin-top: 6px;
}

.btn-orange {
  background-color: var(--primary);
}

.form-card .btn-orange {
  width: 100%;
}

a {
  color: var(--primary);
}
a:hover {
  color: purple;
}

.text-muted a {
  text-decoration: none;
}

#contact {
  /* background-color: var(--primary); */
  color: var(--primary);
  text-align: center;
}

/* .bg-red {
    background-color: var(--primary);
    font-size: 18px;
    font-weight: normal;
    font-kerning: normal;
  } */

.bg-orange {
  background-color: var(--primary);
}

/* #validate-content a {
    color: var(--primary);
  } */

#table-area-pending,
#table-area-signed {
  padding: 20px;
}

table td,
table th {
  color: var(--dark-neutral);
}

table a {
  text-decoration: none;
  color: var(--primary);
}

table a:hover {
  text-decoration: underline;
  color: var(--primary);
}

.nda {
  color: var(--dark-neutral);
  padding: 12px;
}
.nda img {
  height: 25vh;
}
/* Extra small devices (portrait phones, less than 576px) */
@media screen and (max-width: 576px) {
  .nda img {
    width: 20vw;
    height: auto;
  }
}

.nda .footer {
  text-align: center;
}

.header1 {
  text-align: center;
}

/* #validate-content table {
    margin-top: 32px;
    margin-bottom: 40px;
  } */

#dashboard-content table {
  margin-top: 10px;
  margin-bottom: 40px;
}

/* #dashboard-content table .fa-pencil,
  #dashboard-content table .fa-trash {
    color: var(--primary);
    padding-right: 30px;
  }
  
  .table-lt-blue {
    background-color: var(--tertiary);
  } */

.modal {
  color: var(--primary);
}

/* #edit_form .form-group {
    margin-bottom: 6px;
  }
  
  #edit_form .btn {
    margin-top: 20px;
  }
  
  #validate-content li {
    margin-bottom: 20px;
    color: var(--primary);
  }
  
  #add-content .input-group .first {
    border-radius: 0.3rem 0rem 0rem 0.3rem;
  }
  
  #add-content .input-group .last,
  #non-match .input-group .last, */
#search .last {
  border-radius: 0rem 0.3rem 0.3rem 0rem;
}

/* #add-form a {
    color: var(--primary);
  } */

li b {
  color: var(--primary);
}

/* .help-toggler {
    text-decoration: none;
  }
  
  .end {
    margin-bottom: 48px;
  } */

footer,
footer a {
  text-decoration: none;
  font-size: 14px;
  text-align: center;
  color: var(--primary);
}

.modal-dialog {
  max-width: 57% !important;
}
.canvas {
  margin-left: 18% !important;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  margin-top: 13px;
}

.MuiTablePagination-actions {
  margin-top: 3px;
}

.otp-box {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 0px !important; */
  /* margin-right: 100px; */
}

.otp-box .input {
  width: 50px;
  font-size: 20px;
  /* padding: 10px; */
  text-align: center;
  border-radius: 5px;
  margin: 2px;
  border: 2px solid #55525c;
  background: var(--primary);
  font-weight: bold;
  color: white;
  outline: none;
  transition: all 0.1s;
}

.otp-box input:focus {
  border: 2px solid var(--tertiary);
  box-shadow: 0 0 2px 2px #27ffd76a;
}

.more {
  display: none;
}

/* Extra small devices (portrait phones, less than 576px) */
@media screen and (max-width: 576px) {
  #dashboard-content table .fa {
    padding-right: 10px;
  }
  .modal-dialog {
    max-width: 100% !important;
  }

  .more {
    display: block;
  }

  .hide_btns {
    display: none;
  }

  .canvas {
    margin-left: 0% !important;
    width: 100% !important;
  }
  .nda {
    height: 100%;
    width: 100%;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (min-width: 576px) and (max-width: 768px) {
  .modal-dialog {
    max-width: 100%;
  }
  .more {
    display: block;
  }
  .button1 {
    display: none;
  }
  .nda {
    height: 100%;
    width: 100%;
  }

  .hide_btns {
    display: none;
  }
}

/* Extra small devices (portrait phones, less than 576px)
  Small devices (landscape phones, 576px and up)
  Medium devices (tablets, 768px and up) */
@media screen and (max-width: 992px) {
  #copy2,
  #login-content {
    text-align: center;
  }
  .more {
    display: block;
  }
  .button1 {
    display: none;
  }
  #login-content h1 {
    font-size: 24px;
    font-weight: 500;
  }
  .form-card {
    text-align: left;
  }
  .modal-dialog {
    max-width: 100% !important;
  }
  .nda {
    height: 100%;
    width: 100%;
  }
  .tr {
    overflow: scroll !important;
  }

  .hide_btns {
    display: none;
  }
}

/* Medium devices (tablets, 768px and up)
  Large devices (desktops, 992px and up) */
@media screen and (min-width: 768px) {
  .modal-dialog {
    max-width: 100%;
  }
  .nda {
    height: 100%;
    width: 100%;
  }
}

/* Large devices (desktops, 992px and up) */
@media screen and (min-width: 992px) {
  #login-content {
    text-align: left;
  }
}
